.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0px 2px 15px rgba(167, 167, 167, 0.2);
}

.searchContainer {
  display: flex;
  align-items: center;
}
.search {
  border: none;
}
.iconSearch {
  font-size: large;
}

.iconContainer {
  display: flex;
  justify-content: center;
  margin-right: 16px;
  margin-left: 16px;
}
.userName {
  width: 150px;
  margin-left: 16px;
}
.username {
  margin-right: 16px;
}
.avatarContainer {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  cursor: pointer;
}
.avatarImg {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: 'contain';
}
.inputSearch {
  width: 100%;
}
.inputSearch::placeholder {
  color: var(--text) !important;
  opacity: 0.5;
}
.menuLogout {
  padding-left: 16px !important;
  padding-right: 16px !important;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}
.iconBell {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.dropdown {
  position: relative;
}

.menu {
  position: absolute;
  z-index: 1;
  list-style-type: none;
  margin: 18px -150px;
  padding: 0;
  width: 150px;
  border-radius: 0.3rem;
  border: solid 0.1rem #cdcccc83;
}

.menu > li {
  margin: 0;
  height: 33px;
  background-color: #ffffff;
  border-radius: 0.3rem;
}

.menu > li:hover {
  background-color: #e1dede;
}

.menu > li > button {
  width: 100%;
  height: 100%;
  background: none;
  color: inherit;
  padding: 3px;
  font: inherit;
  font-weight: 400 !important;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  border: none;
}
