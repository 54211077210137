.select-component {
  margin-bottom: 10px;
  .lable-mb {
    margin-bottom: 8px;
  }
  .ant-select {
    width: 100%;
    .ant-select-selector {
      height: 35px !important;
    }
    .ant-select-selection-item {
      margin: auto;
    }
    .ant-select-arrow {
      color: #000000;
    }
  }
}
.ant-select-item {
  font-size: 12px !important;
}
.disableSelect {
  .ant-select-selector {
    border-color: #d9d9d9 !important;
    cursor: not-allowed !important;
    &:hover {
      border-color: #e3242b !important;
    }
  }
  .ant-select-arrow {
    display: none;
  }
}
