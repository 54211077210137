.ip-date {
  display: flex;
  align-items: center;
  border-radius: 4px;

  .custom-input-date {
    display: flex;
    border: 1px solid #dfdfdf;
    height: 35px;
    width: 165px;
    border-radius: 4px;
    align-items: center;
  }
  .ip-datePicker {
    width: 165px;
    color: inherit;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #dfdfdf;
    padding: 0px 30px 0px 11px;
    outline: none;
  }
  .date-text {
    position: relative;
    left: 5%;
  }
  .icon-calendar {
    width: 20px;
    margin-left: 135px;
    position: absolute;
  }
  .tidle-sign {
    font-size: 14px;
    font-weight: 400;
    margin-left: 8px !important;
    margin-right: -20px !important;
  }
  .ip-year {
    width: 100%;
    color: inherit;
    height: 35px;
    border-radius: 2px;
    border: 1px solid #dfdfdf;
    outline: none;
  }
  .custom-input-year {
    display: flex;
    border: 1px solid #dfdfdf;
    height: 35px;
    width: 100%;
    border-radius: 2px;
    align-items: center;
  }
  .describe-text {
    font-weight: bold;
    font-size: 14px;
    padding-left: 12px;
  }
  .react-datepicker__close-icon {
    right: 6% !important;
    padding: 0px !important;
    position: absolute !important;
  }
  .react-datepicker__close-icon::after {
    background-color: transparent !important;
    height: 12px !important;
    width: 12px !important;
    padding-right: 0px !important;
    font-size: 16px !important;
    vertical-align: unset !important;
    color: #000000 !important;
  }
}
