.zoomContainer {
  position: absolute;
  z-index: 1;
  bottom: 10px;
  border-radius: 6px;
  padding: 1px;
  width: 75px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
}

.zoomBtn {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  right: 10px;
  bottom: 10px;
  padding: 4px;
  border-radius: 6px;
  background-color: white;
}

.switchTxt {
  width: 75px;
}

.txt {
  margin-bottom: 0px;
  text-align: center;
  font-size: 12px;
}

.line {
  margin-top: 6px;
  margin-bottom: 6px;
  height: 2px;
  background-color: #e5e5e5;
}
