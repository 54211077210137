.collapsed__component {
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(167, 167, 167, 0.2);
    border-radius: 4px;
    padding: 24px;
    width: 100%;
}

.collapsed__component-button {
    width: 100%;
    background-color: unset;
    border: unset;
    padding: 0px !important;
}

.collapsed__component-button-d {
    height: 100%;
    width: 90px;
    display: flex;
    justify-items: center;
}

.collapsed__component-button-text {
    width: 64px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #26292F;
    line-height: 20px;
}

.collapsed__component-button-icon {
    width: 18px;
    height: 20px;
    margin-left: 8px;
    cursor: pointer;
}

.collapsed__component-content {
    height: auto;
    margin-top: 25px;
}

.collapsed__component-d-none {
    display: none !important;
}