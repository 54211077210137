html,
body {
  font-family: 'Work Sans', sans-serif;
  letter-spacing: 0.4px;
  height: 100%;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  background-color: var(--primary-background);
}
:root {
  --primary-main: #d7000f;
  --primary-main-light: #da8cff;
  --primary-background: #f2f3f9;
  --primary-background-white: #fff;
  --primary-gray: #c3bdbd;
  --text: black;
  --menu-text-active: #9a55ff;

  .ant-menu-item-selected {
    background-color: #ffffff !important;
    color: #d7000f !important;
  }
}
.flex {
  display: flex;
  flex-direction: row;
}
.j-center {
  justify-content: center;
}
.j-between {
  justify-content: space-between;
}
.mr-1 {
  margin-right: 8px;
}
.ai-center {
  align-items: center;
}
.ant-input-prefix {
  margin-right: 16px !important;
}
.ant-input-suffix {
  margin-left: 0px !important;
}
.login-page {
  .ant-input-affix-wrapper {
    height: 35px !important;
    padding: 0px 16px !important;
    border-radius: 4px !important;
  }
}
.ant-input-affix-wrapper {
  padding: 0px 16px !important;
}
.ant-input::placeholder {
  color: #6c757d;
  margin-left: 17px;
}
p {
  margin: 0;
  padding: 0;
}
.text-align-center {
  text-align: center;
}
.padding-16 {
  padding: 16px;
}
.c {
  color: var(--primary-purple) !important;
}
.pointer {
  cursor: pointer;
}
.d-flex {
  display: flex;
}
.justify-content-flex-end {
  justify-content: flex-end;
}
.align-items-center {
  align-items: center;
}
.justify-content-space-between {
  justify-content: space-between;
}
.img-w-h_18x {
  width: 18px;
  height: 18px;
}
.mg_0_16x {
  margin: 0px 16px;
}
.text-align-right {
  text-align: right;
}
.btn-submit {
  background-color: #d7000f !important;
  color: #ffffff !important;
  border-color: #d7000f;
  &:hover {
    background-color: #d7000f;
    color: #ffffff;
    border-color: #d7000f;
  }
}
.btn-cancel {
  background-color: #ffffff;
  color: #d7000f;
  border-color: #d7000f;
  &:hover {
    background-color: #ffffff;
    color: #d7000f;
    border-color: #d7000f;
  }
}
.mr-16x {
  margin-right: 16px;
}
.font-w-bold {
  font-weight: bold;
}
.mr-10x {
  margin-right: 10px;
}
.cls_3EACFF {
  color: #3eacff;
}
.justify-content-center {
  justify-content: center;
}
.bgrffffff {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px rgb(167 167 167 / 20%);
  border-radius: 4px;
}
.pd_16x_0x {
  padding: 16px 0px;
}
.bgr3EACFF {
  background-color: #3eacff !important;
}
.bdr3EACFF {
  border-color: #3eacff !important;
}
.pd_16x_16x {
  padding: 16px 16px;
}
.ss-drop-drown {
  margin-top: 16px;
  margin-bottom: 16px;
  .pr-7x {
    padding-right: 7px;
  }
  .pl-7x {
    padding-left: 7px;
  }
  .action {
    margin-top: 16px;
  }
}
.w76 {
  width: 76px;
}
.w116 {
  width: 116px;
}
.h38 {
  height: 38px;
}
.cls_E93A44 {
  color: #e93a44;
}
.margin-group-button {
  margin: 16px 0px;
}
.cls_d7000f {
  color: #d7000f;
}
.cls_5BC146 {
  color: #5bc146;
}
.cls_FDB901 {
  color: #fdb901;
}
.cls_7258FF {
  color: #7258ff;
}
.cls_A8A8A8 {
  color: #a8a8a8;
}
.custom-a {
  a {
    color: #3eacff;
  }
}
.mt-16x {
  margin-top: 16px;
}
.mb-16x {
  margin-bottom: 16px;
}
.custom-select-time {
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 4px;
  width: 96px;
  display: inline-flex;
  .ant-select-selector {
    width: 50px !important;
    height: 35px !important;
    border: unset !important;
    text-align: center;
    box-shadow: unset !important;
  }
  .ant-select-selection-item {
    margin: auto;
  }
  .ant-select-arrow {
    display: none !important;
  }
  .ant-select-selection-item {
    padding-right: unset !important;
  }
  .bdr-minutes {
    border-left: 1px solid #dfdfdf;
    padding: 0px 15px;
    height: 16px;
    margin-top: 10px;
  }
}
.mb-25x {
  margin-bottom: 25px;
}
.custom-error {
  color: #d7000f;
  margin-top: 4px;
}
.bdr-r4x {
  border-radius: 4px;
}
.fs-12x {
  font-size: 12px;
}
.fs-14x {
  font-size: 14px;
}
.fs-16x {
  font-size: 16px;
}
.fs-18x {
  font-size: 18px;
}
.rs-tablet-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1000px;
}
button {
  font-weight: bold !important;
  &:disabled {
    opacity: 0.6 !important;
  }
}
.ant-modal-body {
  font-size: 12px;
}
.ant-form {
  font-size: unset;
}
.custom-padding-modal {
  padding: 30px 0px;
}
.modal-content {
  padding: 0px 5px !important;
}
.padding-footer {
  padding: 30px 0px 0px 0px;
}
.ant-form label {
  font-size: 12px;
}
.ant-form-item-explain-error {
  font-size: 11px;
  color: #d7000f !important;
  width: 110%;
  white-space: break-spaces;
}
.mb-10x {
  margin-bottom: 10px;
}
.custom-form-item {
  input {
    height: 36px;
    font-size: 12px !important;
  }
  .ant-input-password {
    input {
      height: 33px;
      font-size: 12px !important;
    }
  }
}
.flex-left {
  width: 30%;
  display: flex;
  float: left;
  padding: 7px 0;
  align-items: center;
}
.custom-submenu {
  .custom-logo-miharas {
    padding-left: 0px !important;
    padding-right: 0px;
    height: 79px;
  }
  .-title-content {
    width: 100%;
  }
  .ant-menu-submenu-arrow {
    display: none;
  }
  .ant-menu-submenu-title {
    padding-right: 20px;
    padding-left: 15px !important;
  }
  .ant-menu-submenu-open {
    background-color: #d7000f !important;
    color: #ffffff;
    .ant-menu-submenu-title {
      &:hover {
        color: unset !important;
      }
    }
  }
  .ant-menu-item {
    &::after {
      border-right: unset;
    }
  }
  .ant-menu-submenu-title {
    height: 50px !important;
  }
}
.custom-err-form {
  .ant-form-item-explain-error {
    margin-top: 5px;
  }
}
.cursor-not-allow {
  cursor: not-allowed;
}
.total-record {
  font-size: 14px;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}
@media only screen and (max-device-width: 768px) {
  .rs-tablet-mobile {
    min-width: 1200px;
  }
}
@media only screen and (max-device-width: 480px) {
  .rs-tablet-mobile {
    min-width: 1200px;
  }
}

.text-underline {
  text-decoration: underline;
}
