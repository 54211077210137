.input-component {
  .ant-col-12 {
    display: flex;
    flex-direction: column;
  }
  .ant-input {
    border-radius: 2px;
  }
  margin-bottom: 10px;
  .label-mb {
    margin-bottom: 8px;
  }
  .input-select {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.icon-edit {
  margin: 5px;
  width: 18px !important;
  height: 18px !important;
}
.ant-form-item-explain {
  height: auto !important;
  opacity: 1;
}
