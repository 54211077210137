.calculator-sheet {
  .page-label {
    .title {
      font-weight: bold;
      line-height: 16px;
      font-style: normal;
    }
    span {
      font-weight: normal;
      line-height: 16px;
    }
  }
  .submit-btn {
    background: #d7000f;
    border-radius: 4px;
    border: none;
    width: 74px;
    height: 38px;
    color: #fff;
    margin-right: 24px;
  }
  .return-btn {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #d7000f;
    width: 74px;
    height: 38px;
    color: #d7000f;
  }
  .calculator {
    background-color: #fff1f1;
    border-radius: 4px;
  }
  .display {
    justify-content: flex-end;
    display: flex;
    align-items: flex-end;
    width: 99%;
    min-height: 120px;
    background-color: #fff;
    margin-top: 9px;
    border-radius: 4px;
    font-size: 24px;
    font-weight: normal;
    line-height: 24px;
  }
  .display-result {
    margin: 10px 10px 10px 10px;
  }
  .select-component {
    margin-bottom: 5px !important;
  }
  .how-to {
    margin-top: 10px;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    color: #e93a44;
  }
  .option-cond {
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    color: #e93a44;
  }
  .divided-line {
    position: relative;
    height: 1px;
    width: 22px;
    background-color: #000000;
    margin: 0px 2px 0px 2px;
    transform: rotate(90deg);
  }
  .long-devided-line {
    display: flex;
    align-self: center;
    position: relative;
    height: 373px;
    width: 1px;
    background-color: #fdbcc0;
  }
  .number-btn {
    border-radius: 4px;
    height: 35px;
    width: 100%;
    border: none;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: normal !important;
    line-height: 16px;
    cursor: pointer;
  }
  .operator-btn {
    border-radius: 4px;
    height: 35px;
    width: 100%;
    border: none;
    background-color: #dadce0;
    justify-content: space-around;
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: normal !important;
    line-height: 16px;
    cursor: pointer;
    span {
      display: flex;
      justify-content: center;
    }
  }
  .unit-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    .btn-disable {
      color: #a8a8a8;
    }
    button {
      border: none;
      background-color: #dadce0;
      font-size: 14px !important;
      line-height: 16px;
      font-weight: normal !important;
      cursor: pointer;
    }
  }
  .equals-btn {
    border-radius: 4px;
    height: 35px;
    width: 100%;
    border: none;
    background-color: #d7000f;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #fff;
    font-size: 16px;
    font-weight: normal !important;
    line-height: 16px;
  }
  .add-sensor {
    height: 35px;
    width: 74px;
    background-color: #d7000f;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    border: none;
    border-radius: 4px;
    margin-top: 15px;
    cursor: pointer;
  }
  .btn-add {
    margin-right: 10px !important;
  }
  .select-sensor {
    border: none !important;
  }
  .ant-select {
    height: 35px;
  }
  .ant-select-selector {
    height: 35px;
  }
  .ant-row {
    display: flex;
    justify-content: space-between;
  }
  .ant-col-4 {
    padding-right: 10px;
    padding-top: 10px;
  }
  .ant-col-6 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-col-8 {
    padding-top: 10px;
    padding-right: 10px;
  }
  .type-select {
    padding-left: 10px;
    padding-top: 0px !important;
    padding-right: 10px;
  }
  .ant-col-16 {
    border-right: 1px solid #fdbcc0;
  }
  .list-tm-seleted {
    margin-top: 119px;
  }
  .w-btn-selected {
    width: 97px;
  }
  .tm-selected {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .tm-name-selected {
    display: flex;
    border-left: 1px solid #a8a8a8;
    border-right: 1px solid #a8a8a8;
    margin: 0 10px;
    padding: 0 10px;
    font-size: 14px;
    line-height: 14px;
    min-width: 100px;
    justify-content: center;
    align-items: center;
  }
}
.w-h-100 {
  width: 100%;
  height: 100%;
}
.word-break-all {
  word-break: break-all;
}
