.custom-btn-return {
  margin-top: 20px;
  .btn-return-style {
    background-color: #ffffff;
    color: #d7000f;
    border-color: #d7000f;
    width: 74px;
    height: 38px;
    border-radius: 4px;
  }
}
