.ip-time {
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin-left: 10px;

  .ip-timePicker {
    width: 150px;
    color: inherit;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #dfdfdf;
    padding: 0px 30px 0px 11px;
    outline: none;
  }
  .custom-input-time {
    display: flex;
    border: 1px solid #dfdfdf;
    height: 35px;
    width: 150px;
    border-radius: 4px;
    align-items: center;
  }

  .icon-calendar {
    width: 20px;
    margin-left: 120px;
    position: absolute;
  }
  .time-text {
    position: relative;
    left: 5%;
  }
}
.react-datepicker {
  font-size: 12px;
}
.react-datepicker-time__header {
  font-size: 12px;
}
