.change_locale {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
}
.login_page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.login_container {
  background-color: #fff;
  padding: 0 40px;
  width: 626px;
  min-height: 400px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.change_pass_container {
  background-color: #fff;
  padding: 0 40px;
  width: 900px;
  min-height: 400px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.miharas_logo {
  text-align: center;
}
.ic_miharas_logo {
  height: 90px;
  width: 249px;
  margin-top: 50px;
  margin-bottom: 32px;
}
.btn_login {
  width: 100%;
  height: 46px !important;
  margin-top: 20px !important;
  font-weight: 700 !important;
  font-style: normal !important;
  font-size: 14px !important;
}
.foget_password {
  margin-top: 44px;
  margin-bottom: 52px;
  font-size: 14px;
  color: #26292f;
}
.reset_password {
  color: #d7000f;
  cursor: pointer;
}
.name_system {
  margin-bottom: 44px;
  color: #6c757d;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}
.remember_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    margin-bottom: 0;
  }
  span {
    cursor: pointer;
  }
}
.wid100 {
  width: 100%;
}
.wid70 {
  width: 70px;
}
.mr16 {
  margin-right: 16px;
}
.ant-btn {
  width: 100%;
}
.reset_container {
  background-color: #fff;
  padding: 0 40px;
  width: 626px;
  height: 327px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.reset_container .foget_heading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.name_heading {
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  margin-top: 51.5px;
  margin-bottom: 44px;
  text-align: center;
  color: #6c757d;
}
.input_start {
  color: #d7000f;
}
.reset_title_input {
  position: absolute;
  top: 126px;
  font-weight: 700;
  font-style: normal;
  line-height: 16px;
  color: #26292f;
}
.reset_input_noti {
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: -5px;
  color: #d7000f;
}
.btn_reset_container {
  margin-bottom: 24px;
}
.btn_reset_pw {
  height: 100px;
}
.btn_reset_login {
  background-color: #fff;
}
.foget_content {
  position: relative;
  top: 70px;
  margin-top: 12px;
}
.error_message {
  color: #d7000f;
  font-size: 12px;
  margin-bottom: 10px;
}
.submit_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancel_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.password_label {
  color: #000;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 10px;
  line-height: 16px;
}
.signup_title {
  margin-bottom: 44px;
  color: #6c757d;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-weight: bold;
}
