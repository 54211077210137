.cutom-div-csv {
  background-color: #3eacff;
  color: #ffffff;
  height: 28px;
  width: 140px;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 4px;
}
.div-csv-disable {
  opacity: 0.8;
  cursor: not-allowed;
}
.mr-8px {
  margin-right: 8px;
}