.individual-settings {
  .label {
    font-weight: normal;
  }
  .label_bold {
    display: flex;
    font-weight: bold;
    flex-direction: row;
    margin-bottom: 15px;
  }
  .label_bold_2 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 15px;
  }
  .label_bold_3 {
    margin-bottom: 9px;
    font-weight: bold;
  }
  .date_time_select {
    display: flex;
    flex-direction: row;
  }
  .setting_input {
    padding-top: 10;
    margin-bottom: 20;
  }
  .label_bold_4 {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .label_describe {
    display: flex;
    justify-content: flex-end;
    color: #e93a44;
    margin-top: 5px;
  }
  .add-attribute-title {
    width: 28%;
  }
  .edit-columns {
    text-align: center;
    outline: none;
    display: flex;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    height: 32px;
    width: 100%;
  }
  .formula-column {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    height: 32px;
  }
  .button-grp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 30px;
  }
  .submit-btn {
    background: #d7000f;
    border-radius: 4px;
    border: none;
    width: 74px;
    height: 38px;
    color: #fff;
    margin-right: 24px;
  }
  .return-btn {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #d7000f;
    width: 74px;
    height: 38px;
    color: #d7000f;
  }
  .add-new-device {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .add-new-label {
    margin-left: 5px;
  }
  .add-btn {
    position: relative;
    background: #3eacff;
    color: #fff;
    border-radius: 4px;
    border: none;
    width: 48px;
    height: 28px;
    left: 20%;
    cursor: pointer;
  }
  .add-new-btn {
    background: #3eacff;
    color: #fff;
    border-radius: 4px;
    border: none;
    width: 42px;
    height: 28px;
    font-weight: normal !important;
    cursor: pointer;
  }
  .ant-input {
    font-size: 12px;
  }
  .ant-form-item {
    margin-bottom: 0;
    font-size: 12px;
  }
  .ant-form-item-explain-error {
    position: absolute;
    top: 35px;
    width: 100%;
  }
}
.custom-table-formula {
  .ant-table-tbody {
    td {
      padding: 24px 8px !important;
    }
  }
}
