.admin-management {
  .label-bold {
    font-weight: bold;
    margin-bottom: 8px;
  }

  .view-btn-filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 25px;
  }

  .btn-create {
    width: 100px;
  }

  .btn-search {
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    min-width: 105px !important;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .btn-red {
    min-width: 73px;
    height: 38px;
    background-color: #d7000f;
    color: #ffffff;
  }

  .btn-red:hover,
  .btn-red:focus {
    background-color: #d7000f !important;
    color: #ffffff !important;
  }

  .btn-update-fil {
    margin-right: 10px;
  }

  .btn-cancel {
    width: 110px;
    height: 38px;
    background-color: #ffffff;
    color: #d7000f;
    border: 1px solid #d7000f;
  }

  .btn-cancel:hover,
  .btn-cancel:focus {
    background-color: #ffffff !important;
    color: #d7000f !important;
  }

  .btn-return {
    width: 73px;
    padding: 0 !important;
  }

  .custom-table {
    padding-bottom: 16px;
    .ant-table-thead {
      tr {
        th {
          // background: #fff1f1;
          // padding: 8px 16px;
          &::before {
            height: unset !important;
          }
        }
      }
    }
  }

  .view-select {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: 100%;

    .select-component .ant-select {
      width: 100%;
    }

    .select-component .ant-select .ant-select-selector {
      height: 35px;
      display: flex;
      align-items: center;
      border-radius: 4px;
    }

    .select-component .ant-select-arrow {
      color: #000000;
    }
  }

  .text-center {
    text-align: center;
  }

  .ip-search-mail {
    height: 38px;
    width: 100%;
    padding: 0 11px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border: 1px solid #dfdfdf;
    outline: none;
  }
  .search-box {
    .ant-select-selector {
      width: 100% !important;
      height: 38px !important;
    }
  }
}
// info detail
.info-detail {
  .label-bold {
    font-weight: bold;
    margin-bottom: 8px;
  }

  .view-btn-ft {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
  }

  .btn-edit {
    width: 73px;
    height: 38px;
    background-color: #d7000f;
    color: #ffffff;
  }

  .btn-edit:hover,
  .btn-edit:focus {
    background-color: #d7000f !important;
    color: #ffffff !important;
  }

  .btn-del {
    width: 73px;
    height: 38px;
    background-color: #d7000f;
    color: #ffffff;
    margin: 0 20px;
  }

  .btn-del:hover,
  .btn-del:focus {
    background-color: #d7000f !important;
    color: #ffffff !important;
  }

  .btn-return {
    width: 73px;
    height: 38px;
    background-color: #ffffff;
    color: #d7000f;
    border: 1px solid #d7000f !important;
    padding: 0 !important;
  }

  .btn-return:hover,
  .btn-return:focus {
    background-color: #ffffff !important;
    color: #d7000f !important;
  }
  // info edit
  .btn-cancel {
    width: 110px;
    margin-left: 10px;
  }

  .text-note {
    display: flex;
    padding-top: 3px;
    color: #e93a44;
    justify-content: flex-end;
  }

  .margin-left-10 {
    margin-left: 10px;
  }

  .inline {
    display: flex;
    justify-content: flex-end;
  }
}
