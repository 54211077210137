.start-date {
  font-size: 14;
  font-weight: bold;
  margin-bottom: 8px;
}
.end-date {
  font-size: 14;
  font-weight: bold;
  margin-bottom: 8px;
}
.inline {
  display: flex;
  justify-content: flex-end;
}
.customer-management {
  .select-component {
    .ant-select {
      .ant-select-selector {
        height: 32px !important;
      }
    }
  }
  .group-btn {
    display: flex;
    justify-content: flex-end;
  }
  .disable-btn {
    opacity: 0.8;
    cursor: not-allowed !important;
  }
  .bulk-send-mail {
    margin-right: 12px;
    .send-mail {
      background-color: #3eacff;
      color: #ffffff;
      height: 28px;
      width: 100%;
      align-items: center;
      justify-content: space-evenly;
      border-radius: 4px;
      padding: 10px;
      span {
        font-weight: 400;
      }
    }
  }
  .send-mail-alert {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #d7000f;
    span {
      color: #3eacff;
      text-decoration: underline;
    }
  }
  .cutom-div-csv {
    span {
      font-weight: 400;
    }
  }
  .ant-table {
    overflow-x: scroll;
    .ant-table-container {
      min-width: 1300px;
    }
  }
}
.inline {
  display: flex;
  justify-content: flex-end;
}
.group-btn-create {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 32px 0px 16px 0px;
  .ant-form-item {
    margin-bottom: 0px !important;
  }
}

.create-customer {
  .custom-input-date {
    width: 100% !important;
    border-radius: 2px !important;
    .date-text {
      left: 1% !important;
    }
    .icon-calendar {
      margin-left: 95% !important;
    }
  }
  .custom-order {
    .custom-input-date {
      width: 100% !important;
      height: 32px !important;
      border-radius: 2px !important;
      .date-text {
        left: 3% !important;
      }
      .icon-calendar {
        margin-left: 90% !important;
      }
    }
  }
  .zip-code-container {
    .ant-input-affix-wrapper {
      height: 35px !important;
    }
    #zipCode {
      height: 32px !important;
    }
    .ant-input {
      height: 32px !important;
    }
  }
  .customer-key-input {
    .ant-input {
      height: 35px !important;
    }
    margin-bottom: 24px;
    .lable-mb {
      margin-bottom: 8px !important;
    }
  }
  .start-date {
    font-size: 12px !important;
  }
  .end-date {
    font-size: 12px !important;
    margin-bottom: 12px !important;
  }
  .err-text {
    font-size: 12px;
    color: #d7000f !important;
  }
  .customer-key-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    .refresh-customer-key {
      background-color: #3eacff;
      color: #ffffff;
      height: 28px;
      width: 145px;
      align-items: center;
      justify-content: space-evenly;
      border-radius: 4px;
      padding: 10px;
      margin-left: 8px;
      span {
        font-weight: 400;
      }
    }
  }
}

.detail-cus-ct1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  .ct1-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 47.5%;
    word-break: break-word;
  }
  .group-btn-del {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 32px;
    .btnEdit {
      width: 130px !important;
    }
  }
  .dashboard-detail {
    .pdr {
      padding-right: 15px;
    }
    .pdl {
      padding-left: 15px;
    }
    .pd {
      padding: 20px 16px 0px 16px;
    }
    .h135x {
      height: 135px;
    }
    .wh45x {
      width: 45px;
      height: 45px;
    }
    .wh32x {
      width: 32px;
      height: 32px;
    }
    .custom-bdr-bt {
      border-bottom: 1px solid #dfdfdf;
    }
    .h80x {
      height: 80px;
    }
    .wh24x {
      width: 24px;
      height: 24px;
    }
    .pt5x {
      padding-top: 5px;
    }
    .mt20x {
      margin-top: 20px;
    }
    .fw500 {
      font-weight: 500;
    }
    .fs_22x {
      font-size: 22px;
    }
    .ml10x {
      margin-left: 10px;
    }
    .pl20x {
      padding-left: 20px;
    }
    .h75x {
      height: 75px;
    }
    .mlRow {
      margin-left: 40px;
    }
    .custom-link {
      a {
        color: #8a8f9b;
        font-weight: bold;
        text-decoration-line: underline;
      }
    }
  }
}
