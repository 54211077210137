// table scss
.custom-table {
  .ant-table {
    font-size: 12px;
  }
  .ant-table-thead {
    tr {
      th {
        background: #fff1f1;
        padding: 8px 8px;
        font-weight: bold;
        &::before {
          height: unset !important;
        }
        &:first-child {
          text-align: center;
        }
      }
    }
  }
  .ant-table-tbody {
    tr {
      &:nth-child(2n) {
        background-color: #f7f7f7;
      }
    }
    td {
      padding: 16px 8px;
      &:first-child {
        text-align: center;
      }
    }
  }
  .ant-table-cell {
    word-break: break-word;
  }
}

// group detail management table
.group-detail-management {
  .ant-table {
    overflow-x: scroll;
    .ant-table-container {
      min-width: 1200px;
    }
  }
}
.user-detail-sensor-management {
  .ant-table {
    overflow-x: scroll;
    .ant-table-container {
      min-width: 1300px;
    }
  }
  .user-detail-sensor-management {
    .ant-table {
      overflow-x: scroll;
      .ant-table-container {
        min-width: 1200px;
      }
    }
  }
}
.order-create {
  .ant-table {
    overflow-y: scroll;
    .ant-table-container {
      max-height: 283px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .display-management-table {
    .ant-table {
      overflow-x: scroll;
      .ant-table-container {
        min-width: 1100px;
      }
    }
  }
}
