.order-create {
  .label-bold {
    font-weight: 700;
    margin-bottom: 16px;
    font-size: 14px;
  }
  .ant-select {
    .ant-select-selector {
      height: 32px !important;
    }
  }
  .order-date-select {
    font-weight: bold;
    margin-bottom: 8px;
  }
  .custom-input-date {
    width: 100% !important;
    height: 32px;
    border-radius: 2px !important;
    .date-text {
      font-size: 12px !important;
      left: 1% !important;
    }
    .icon-calendar {
      margin-left: 95% !important;
    }
  }
  .label-regis-order {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .unship-order-container {
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: #ef999f;
    .table-unship-order {
      .unship-order-label {
        font-size: 12px;
        color: #d7000f;
        margin-bottom: 24px;
      }
    }
  }
  .group-btn-add-remove {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ship-order-label {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .group-btn {
    display: flex;
    justify-content: flex-end;
  }
  .row-spc-8 {
    padding-bottom: 14px;
  }
  .row-label {
    font-size: 12px !important;
    font-weight: bold;
    font-style: normal;
    line-height: 15px;
  }
  .row-content {
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    line-height: 15px;
  }
  .row-button-download-csv {
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
    margin-bottom: 11px;
  }
  .ic-download {
    margin-right: 5px;
  }
  .csv-download-btn {
    height: 28px;
    background: #3eacff;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 100 !important;
    color: #ffffff;
    border: none;
    display: inline-flex;
    align-items: center;
    padding: 0 4px;
  }
  .csv-download-btn:hover {
    background: #3eacff;
    color: #ffffff;
    border: none;
  }
  .csv-download-btn:focus {
    background: #3eacff;
    color: #ffffff;
    border: none;
  }
  .edit-btn {
    width: 76px;
    height: 43px;
    color: #ffffff;
  }
  .cancel-btn {
    width: 76px;
    height: 43px;
  }
  .custom-table-order-detail {
    .ant-table {
      font-size: 12px;
      min-height: 400px;
    }
    .ant-table-thead {
      tr {
        th {
          padding-right: 15px;
          background: #fff1f1;
          padding-left: 16px;
          padding-top: 10px;
          font-weight: bold;
          &::before {
            height: unset !important;
          }
          &:first-child {
            text-align: center;
          }
        }
      }
    }
    .ant-table-tbody {
      overflow: hidden !important;
      tr {
        &:nth-child(2n) {
          background-color: #f7f7f7;
        }
      }
      td {
        padding-left: 16px;
        padding-right: 15px;
        &:first-child {
          text-align: center;
        }
      }
    }
    .ant-table-cell {
      word-break: break-word;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .custom-table {
    .ant-table {
      font-size: 12px;
    }
    .ant-table-thead {
      tr {
        th {
          padding-right: 15px;
          background: #fff1f1;
          padding-left: 16px;
          padding-top: 10px;
          font-weight: bold;
          &::before {
            height: unset !important;
          }
          &:first-child {
            text-align: center;
          }
        }
      }
    }
    .ant-table-tbody {
      overflow: hidden !important;
      tr {
        &:nth-child(2n) {
          background-color: #f7f7f7;
        }
      }
      td {
        padding-left: 16px;
        padding-right: 15px;
        &:first-child {
          text-align: center;
        }
      }
    }
    .ant-table-cell {
      word-break: break-word;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
}
.inline {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
