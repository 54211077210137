.dashboard-detail {
  .pdr {
    padding-right: 15px;
  }
  .pdl {
    padding-left: 15px;
  }
  .pd {
    padding: 20px 16px 0px 16px;
  }
  .h135x {
    height: 135px;
  }
  .wh45x {
    width: 45px;
    height: 45px;
  }
  .wh32x {
    width: 32px;
    height: 32px;
  }
  .custom-bdr-bt {
    border-bottom: 1px solid #dfdfdf;
  }
  .h80x {
    height: 80px;
  }
  .wh24x {
    width: 24px;
    height: 24px;
  }
  .pt5x {
    padding-top: 5px;
  }
  .mt20x {
    margin-top: 20px;
  }
  .fw500 {
    font-weight: 500;
  }
  .fs_22x {
    font-size: 22px;
  }
  .ml10x {
    margin-left: 10px;
  }
  .pl20x {
    padding-left: 20px;
  }
  .h75x {
    height: 75px;
  }
  .mlRow {
    margin-left: 40px;
  }
  .custom-link {
    a {
      color: #3eacff;
      font-weight: bold;
      text-decoration-line: underline;
    }
  }
}
.custom-see-more {
  a {
    width: 116px;
    background: #d7000f !important;
    border-radius: 4px;
    color: #ffffff;
    height: 38px;
  }
}
.w42x-h28x {
  width: 42px;
  height: 28px;
}
.mg-dash-table {
  margin-top: 20px;
  margin-bottom: 20px;
}
