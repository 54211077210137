.serial-regis {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  background-color: #d7000f;
  border-radius: 4px;
  height: 80px;
  cursor: pointer;
}
.serial-no-management {
  .label-w-bold {
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 32px;
  }
  .year-picker-label {
    height: 22px;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 8px;
    span {
      color: #d7000f;
    }
  }
  .ip-date {
    .react-datepicker__year-wrapper {
      justify-content: center;
    }
  }
  .input-component {
    .ant-input-disable {
      background-color: #ffffff !important;
    }
    input {
      height: 35px !important;
    }
  }
  .no-imei {
    color: #d7000f;
  }
  .copy-success-text {
    color: #d7000f;
  }
  .blank-qr {
    display: flex;
    width: 170px;
    height: 170px;
    background-color: #ededed;
    padding: 20px;
    .blank-text {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #3eacff;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
    }
  }
  .qr-warning {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .warning-text {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-left: 8px;
      display: flex;
      color: #d7000f;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }
  .group-switch {
    margin-top: 12px !important;
    margin-bottom: 24px !important;
    .ant-switch-checked {
      background-color: #458af2;
      .ant-switch-handle {
        left: calc(100% - 18px - 6px);
      }
    }
    .custom-switch {
      transform: scale(-1, 1) !important;
      border-radius: 4px !important;
      height: 28px !important;
      width: 50px !important;
      .ant-switch-handle::before {
        height: 22px !important;
        width: 22px !important;
        margin-top: 1px !important;
        border-radius: 4px !important;
      }
    }
    .btn-download-json {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 81px;
      height: 28px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      border-radius: 4px;
      background-color: #3eacff;
      color: #ffffff;
    }
  }
  .code-text {
    display: flex;
    align-items: center;
    padding: 16px 48px;
    justify-content: flex-end;
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .download-code-container {
    display: flex;
    align-items: flex-end;
    .download-code {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 130px;
      height: 28px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      border-radius: 4px;
      background-color: #3eacff;
      color: #ffffff;
    }
  }
  .group-btn-action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;
  }
  .pd-bot-20 {
    padding-bottom: 20px !important;
  }
}
.registration-result {
  padding-left: 16px;
  font-size: 14px;
  font-weight: bold;
}
.view-btn-ft {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.pd_top_16x {
  padding: 16px 0px 0px 0px !important;
}
