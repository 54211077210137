.contentContainer {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 79px;
}
.logo {
  height: 45px;
  // margin: 16px;
  width: auto;
  text-align: center;
  cursor: pointer;
}

.avatarImgContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.avatar {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  margin-right: 16px;
}
.avtTitleContainer {
  margin: 0;
  padding: 0;
}
.userName {
  font-size: 14px;
  height: 20px;
  font-weight: 700;
}
.userRole {
  color: var(--primary-gray);
  font-size: 12px;
}
.icRoute {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
