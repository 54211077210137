.input-w {
  width: calc(100% - 107px);
}
.w_107x {
  width: 107px;
}
.img_20x {
  width: 20px;
  height: 20px;
}
.h_35x {
  input {
    height: 35px;
    font-size: 12px;
  }
  button {
    height: 35px;
    font-size: 12px;
    left: -1px;
    border-radius: 0px 2px 2px 0px;
  }
}
.mr_10x {
  margin-right: 10px;
}
.ant-select {
  font-size: 12px !important;
}
.custom-search-select {
  .ant-select-selector {
    height: 35px !important;
  }
  .ant-select-selection-placeholder {
    margin: auto;
  }
  .ant-select-selection-search-input {
    height: 100% !important;
  }
  .ant-select-selection-item {
    margin: auto;
  }
  .ant-select-arrow {
    color: #000000;
  }
  .anticon {
    color: #000000;
  }
}
