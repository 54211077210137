.serial-body {
  .label {
    font-size: 12px !important;
  }
  .block {
    padding: 24px;
  }
  .row-layout {
    display: flex;
    margin-bottom: 12px;
    .ant-form-item {
      margin-bottom: 12px !important;
      border-radius: 4px !important;
    }
  }
  .col-layout {
    width: 50%;
  }
  .date-picker-container {
    width: 50% !important;
    .react-datepicker-wrapper {
      width: 100% !important;
    }
  }
  .inline {
    display: flex;
    justify-content: flex-end;
  }
  .block .btn-create {
    background-color: #d7000f !important;
    border-radius: 4px;
    height: 42px;
    width: 102px;
    color: #ffffff !important;
  }
  .group-btn-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .dropdown {
    width: 95%;
  }

  .label {
    color: #26292f;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 12px;
  }

  .col-layout .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #dfdfdf !important;
    border-radius: 4px !important;
    min-height: 42px;
  }

  .dropdown .ant-select-selector .ant-select-selection-item {
    min-height: 42px;
    display: inline-flex;
    align-items: center;
  }
  .checkbox-layout .checkbox {
    margin-right: 8px;
  }
  .checkbox-layout .checkbox label.ant-checkbox-wrapper.ant-checkbox-group-item {
    margin-right: 8px;
  }
  .datepicker-block {
    width: 100%;
  }
  .datePicker {
    width: 100%;
  }

  .checkbox-layout .checkbox-group {
    height: 42px;
    display: flex;
    align-items: center;
    .ant-checkbox-wrapper {
      font-size: 12px !important;
    }
  }

  .custom-input-date {
    display: flex;
    border: 1px solid #dfdfdf;
    height: 35px !important;
    border-radius: 4px;
    align-items: center;
    width: 100% !important;
  }
  .custom-input-date .icon-calendar {
    width: 20px;
    right: 0;
    margin-right: 10px;
    position: absolute;
  }

  .date-text {
    width: 90%;
    font-size: 12px;
  }

  .start-date {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .ip-date .tidle-sign {
    margin-left: 2% !important;
  }

  .row-layout .upload-button {
    border-radius: 4px;
    height: 28px;
    background: #3eacff !important;
    color: #ffffff !important;
    margin-left: 16px;
    font-size: 12px;
    font-weight: 400 !important;
    line-height: 16.42px !important;
    border-color: #3eacff !important;
  }

  .table {
    width: 100%;
  }

  .center-item {
    display: flex;
    justify-content: center;
  }

  .row-unset-margin-bottom {
    margin-bottom: 0 !important;
  }

  .table {
    .ant-table {
      font-size: 12px;
    }
    .ant-table-thead {
      tr {
        th {
          white-space: pre;
          background: #fff1f1;
          font-weight: bold;
          &::before {
            height: unset !important;
          }
          &:first-child {
            text-align: center;
          }
        }
      }
    }
    .ant-table-tbody {
      overflow: hidden !important;
      tr {
        &:nth-child(2n) {
          background-color: #f7f7f7;
        }
      }
      td {
        &:first-child {
          text-align: center;
        }
      }
    }
    .ant-table-cell {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 16px 6px !important;
  }

  .submit-button {
    width: 74px;
    height: 43px !important;
    color: #ffffff !important;
    background-color: #d7000f !important;
    border-radius: 4px !important;
    border-width: 0;
    cursor: pointer;
    font-size: 14px !important;
  }

  .cancel-button {
    margin-left: 24px;
    height: 43px !important;
    border-radius: 4px;
    border: solid 1px #d7000f !important;
    color: #d7000f !important;
  }

  .justify-item-right {
    display: flex;
    justify-content: flex-end;
  }
  .pagination {
    display: flex !important;
    justify-content: flex-end;
  }
  .pagination-bottom .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: unset !important;
  }
  .msg-error {
    color: #d7000f;
    font-size: 12px;
    margin-top: 4px;
  }
}

.dropdown-layout {
  .ant-select {
    height: 35px !important;
    .ant-select-selector {
      height: 35px !important;
      min-height: 35px !important;
      .ant-select-selection-search {
        height: 35px !important;
        .ant-select-selection-search-input {
          height: 35px !important;
        }
      }
    }
  }
}

.serial-no__column-sortable {
  display: flex;
  cursor: pointer;
  background-color: unset;
  border: unset;
  transition: transform 0.5s;
  .serial-no__column-sortable-title {
    margin-right: 5px;
  }
  .serial-no__column-sortable-icon {
    height: 15px;
  }
}

.serial-no__column-sortable-desc {
  .serial-no__column-sortable-icon {
    transform: rotate(-180deg) !important;
    transition: transform 0.1s;
  }
}
