.custom-breadscrumb {
  margin: 16px 16px;
  .img-w-h {
    width: 24px;
    height: 24px;
  }
  .ant-breadcrumb {
    span {
      &:nth-child(2n) {
        .ant-breadcrumb-link {
          color: #d7000f !important;
          a {
            color: #d7000f !important;
          }
        }
      }
      &:last-child {
        color: #6c757d;
      }
    }
  }
}
