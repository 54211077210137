.show-sensor-detail {
  margin-top: 16px;
  .item-sensor-detail {
    margin-bottom: 10px;
  }
  .custom-btn-item-detail {
    height: 28px;
    padding: 0px;
  }
}
.block-table {
  margin-top: 16px;
  .custom-table {
    padding-bottom: 16px;
    .ant-table-thead {
      tr {
        th {
          background: #fff1f1;
          padding: 8px 8px;
          &::before {
            height: unset !important;
          }
        }
      }
    }
    .ant-table-tbody {
      tr {
        &:nth-child(2n) {
          background-color: #f7f7f7;
        }
      }
    }
  }
}
.btn-return {
  padding-bottom: 20px;
}
.custom-img-line {
  width: 10px;
  margin: 0 10px;
}
