.create-container {
  & .ant-form-item {
    margin-bottom: 15px;
  }
  & .select-component {
    margin-bottom: unset;
  }
  & .ant-picker,
  .ant-input {
    min-height: 35px;
  }
  & .label-mb,
  & .search-box-title,
  & .btn-label {
    margin-bottom: 5px;
  }
  .add-info {
    margin-top: 16px;
  }
  .btn-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
  }
}
