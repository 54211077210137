.ant-typography {

   &__optional {
      color: #E93A44;
      font-size: 14;
   }  
}

.ant-select {
   width: 100%;
   .ant-select-selector {
     height: 35px !important;
   }
   .ant-select-selection-item {
     margin: auto;
   }
   .ant-select-arrow {
     color: #000000;
   }
 }

.ant-select-item {
   font-size: 12px !important;
 
   .icon-right {
     display: flex;
     align-items: center;
     gap: 8px;

     .content {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width:100px; 
     }
 
     .ant-btn-icon-only {
       width: 16px !important;
       height: 16px !important;
 
       * {
         font-size: 8px;
       }
     }
 
     .ant-btn-circle {
       min-width: 16px !important;
     }
   }
 }
 