.kasaki-shape-management {
  .kasaki-manenger {
    background-color: #fff1f1;
    .kasaki-component {
      height: 40px;
      align-items: center;
      color: #26292f;
      .kasaki-itemCenter {
        text-align: center;
      }
    }
  }
  .filter-label {
    font-size: 12px !important;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .kasaki-Add {
    margin-left: 34px;
    .kasaki-btnAdd {
      // left: 95%;
      height: 38px;
      border-radius: 4px;
      background-color: #d7000f;
      .kasaki-iconAdd {
        margin-right: 6px;
      }
      .kasaki-textAdd {
        color: #ffffff;
      }
      .kasaki-textAdd:hover {
        color: #ffffff;
      }
    }
  }
  .kasaki-btn {
    .kasaki-btnAdd {
      // left: 95%;
      border-radius: 4px;
      background-color: #d7000f;
      .kasaki-iconAdd {
        margin-right: 6px;
      }
      .kasaki-textAdd {
        color: #ffffff;
      }
      .kasaki-textAdd:hover {
        color: #ffffff;
      }
    }
  }
  .kasaki-btnCen {
    .kasaki-btnAdd {
      height: 38px;
      // left: 95%;
      border-radius: 4px;
      border: solid 1px #d7000f !important;
      .kasaki-textAdd {
        color: #d7000f;
      }
    }
  }
  .katashiki-label {
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    padding: 24px;
  }
  .btn-add {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
  }
  .group-btn-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px;
  }
  .input-error-parent {
    display: inline-flex;
    width: 100%;
  }
  .input-error {
    font-size: 12px;
    color: #d70000;
    padding-left: 10px;
    padding-top: 10px;
    width: 15.7%;
  }
}

input#customerNamekasaki {
  width: 75%;
}
input#customerNamekasaki2 {
  width: 85%;
}
.kasaki-icDelete {
  cursor: pointer;
  margin-top: 2px;
  text-align: center;
}
.kasaki-hr {
  width: 100%;
  background: #dfdfdf;
  height: 1px;
}
.ant-table-cell {
  .ant-select {
    width: 100%;
  }
}
.kasaki-component-item {
  height: 35px;
  .kasaki-item {
    .kasaki-lableErr {
      margin-top: -25px;
      font-size: 12px;
      color: #d7000f;
    }
  }
  .kasaki-itemCenter {
    text-align: center;
  }
}
.ant-col.ant-col-24.titleErr {
  font-size: 12px;
  color: #d7000f;
  margin-top: -24px;
}
input.ant-input.ant-input-disabled {
  // background: white;
  color: #d9d9d9;
}
// list katashiki

.kasaki-list {
  .bgrffffff {
    .kasaki-list-shape-btn {
      .ss-drop-drown {
        .kasaki-list-button-back {
          height: 42px;
          background: #d7000f;
          color: #ffffff;
          border-radius: 4px;
          font-size: 12px !important;
        }
      }
    }
    .kasaki-list-table {
      .ss-drop-drown {
        .kasaki-btnAdd {
          background: #3eacff;
          border-radius: 4px;
          .kasaki-iconAdd {
            margin-right: 6px;
          }
          .kasaki-textAdd {
            color: #ffffff;
          }
          .kasaki-textAdd:hover {
            color: #ffffff;
          }
        }
      }
      .katasiki-component-shapeTable {
        background-color: #fff1f1;
        .kasaki-component-table {
          height: 43px;
          justify-content: center;
          align-items: center;
          .kasaki-component {
            height: 40px;
            align-items: center;
            color: #26292f;
            .kasaki-itemCenter {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .edit-btn {
    width: 42px;
    height: 28px;
    background: #3eacff;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }

  .error-message {
    color: #d7000f;
    font-size: 10px;
  }
  .input-error {
    border-color: #d7000f;
  }
  .point-disable {
    cursor: unset !important;
  }
}
button.katashiki-btnReset {
  width: 74px;
  height: 43px;
  border: 1px solid #d70000;
  color: #d7000f;
  border-radius: 4px;
}
button.ant-btn.kasaki-btnAdd-list {
  height: 38px;
  border: solid 1px #d7000f !important;
  border-radius: 4px;
  .kasaki-textAdd {
    font-size: 12px !important;
    color: #d7000f;
  }
}
label.ant-checkbox-wrapper.ant-checkbox-group-item {
  margin-right: 24px;
}
button.ant-btn.ant-btn-default.w42x-h28x.pointer.register {
  height: 28px;
  padding: 0;
  background: #3eacff;
  color: #ffffff;
}
button.ant-btn.ant-btn-default.w42x-h28x.pointer.register:hover {
  border: #3eacff;
}
// .ant-select.kasaki-optionItem.ant-select-single.ant-select-show-arrow {
//   width: 100%;
// }

.keyword-options {
  font-size: 12px;
  width: 100%;
}

.kasaki-shape-btn {
  display: flex;
  justify-content: flex-end;
  flex-flow: unset !important;
}

.btn-confirm-katashiki-detail {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
}
.katashiki-detail-custom-table {
  .ant-table {
    font-size: 12px;
  }
  .ant-table-thead {
    tr {
      th {
        background: #fff1f1;
        font-weight: bold;
        &::before {
          height: unset !important;
        }
        &:first-child {
          text-align: center;
        }
      }
    }
  }
  .ant-table-tbody {
    overflow: hidden !important;
    tr {
      height: 40px !important;
      &:nth-child(2n) {
        background-color: #f7f7f7;
      }
    }
    td {
      &:first-child {
        text-align: center;
      }
    }
    .ant-form-item {
      margin-bottom: 12px !important;
      margin-top: 12px !important;
    }
  }
  .ant-table-cell {
    height: 40px !important;
    .ant-row {
      flex-flow: row wrap;
      display: inline;
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 0 !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}
.inline {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
