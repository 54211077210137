.create-user {
  .ant-form-item {
    margin-bottom: 12px;
  }
  .mb-lb-form {
    margin-bottom: 5px;
  }
  .ant-form-item-explain-error {
    margin-top: 5px;
  }
  .ant-descriptions-header {
    margin-bottom: unset;
    .ant-descriptions-title {
      font-size: 10px;
      font-weight: normal;
      color: #d7000f;
      margin-top: 3px;
    }
  }
}
.inline {
  display: flex;
  justify-content: flex-end;
}

.user-management {
  .search-layout {
    padding: 16px;
  }
  .dropdown {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 32px !important;
    }
  }
  .csvBtn {
    width: fit-content !important;
    padding: 0px 8px;
    display: flex;
    justify-content: center;
  }
  .remove-btn {
    background-color: #E93A43;
    margin-left: 16px;
    border-radius: 4px;
    width: fit-content;
    padding-right: 8px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
  .remove-title {
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
  }
  .remove-btn-disable {
    opacity: 0.8;
    cursor: not-allowed;
  }
}
