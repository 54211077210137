.sensor-management {
  .mg_10x {
    margin: 10px 0px;
  }
  .cls_3EACFF {
    color: #3eacff;
  }
  .custom-button {
    button {
      background-color: #3eacff;
      border-color: #3eacff;
    }
  }
  .mt-return {
    margin-top: 30px;
  }
  .cls_E93A44 {
    color: #e93a44;
  }
  .cls_5BC146 {
    color: #5bc146;
  }
}
.search-email-group {
  font-size: 14px;
}
.inline {
  display: flex;
  justify-content: flex-end;
}
