.new-regis-form {
  .ant-form-item {
    margin-bottom: 12px;
  }
  .mb-lb-form {
    margin-bottom: 5px;
  }
  .ant-form-item-explain-error {
    margin-top: 5px;
  }
  .ant-descriptions-header {
    margin-bottom: unset;
    .ant-descriptions-title {
      font-size: 10px;
      font-weight: normal;
      color: #d7000f;
      margin-top: 3px;
    }
  }
  .form-title {
    margin-bottom: 24px !important;
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;
    color: #6c757d;
  }
  .label-bold {
    font-weight: bold;
    span {
      color: #d7000f;
    }
  }
}
