.ip-month {
  display: flex;
  align-items: center;
  border-radius: 4px;

  .custom-input-date {
    display: flex;
    border: 1px solid #dfdfdf;
    height: 35px;
    width: 165px;
    border-radius: 4px;
    align-items: center;
  }
  .ip-datePicker {
    width: 165px;
    color: inherit;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #dfdfdf;
    padding: 0px 30px 0px 11px;
    outline: none;
  }
  .date-text {
    position: relative;
    left: 5%;
  }
  .icon-calendar {
    width: 20px;
    margin-left: 135px;
    position: absolute;
  }
  .tidle-sign {
    font-size: 14px;
    font-weight: 400;
    margin-left: 8px !important;
    margin-right: -20px !important;
  }
  .ip-year {
    width: 100%;
    color: inherit;
    height: 35px;
    border-radius: 2px;
    border: 1px solid #dfdfdf;
    outline: none;
  }
  .custom-input-year {
    display: flex;
    border: 1px solid #dfdfdf;
    height: 35px;
    width: 100%;
    border-radius: 2px;
    align-items: center;
  }
  .describe-text {
    font-weight: bold;
    font-size: 14px;
    padding-left: 12px;
  }
  .react-datepicker {
    .react-datepicker__month-container {
      .react-datepicker-year-header {
        display: none !important;
      }
    }
    .react-datepicker__navigation--next {
      display: none !important;
    }
    .react-datepicker__navigation--previous {
      display: none !important;
    }
  }
}
