.ip-textArea {
  .border-r {
    border-radius: 4px;
  }

  .border-r:disabled {
    color: #000000;
    background-color: transparent;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: auto;
    opacity: 1;
  }
}
